<template>
  <div>
    <Modal class="pop-inquiry-success" footer-hide v-model="showSuccess">
      <div class="top">
        <div class="product">{{ inquiry.product_name }}</div>
        <div class="status status-completed">Completed</div>
      </div>
      <!--      <div class="background-image">-->
      <!--        <img-->
      <!--          src="https://connexima.s3-us-west-1.amazonaws.com/documents/mv2xuaYKbzeLebHExwpFr2.png"-->
      <!--          alt=""-->
      <!--        />-->
      <!--      </div>-->
      <div class="content">
        <div class="line-item">
          <div class="item">
            <div class="label">Quantity</div>
            <div class="value">{{ inquiry.quantity }}</div>
          </div>
          <div class="item">
            <div class="label">Quality Standard</div>
            <div class="value">{{ inquiry.quality_standard }}</div>
          </div>
          <div class="item">
            <div class="label">Application</div>
            <div class="value">{{ inquiry.application }}</div>
          </div>
        </div>
        <div class="line-item">
          <div class="item">
            <div class="label">Main Specs</div>
            <div class="value">{{ inquiry.main_specs }}</div>
          </div>
          <div class="item">
            <div class="label">Price Basis</div>
            <div class="value">{{ inquiry.price_basis }}</div>
          </div>
          <div class="item">
            <div class="label">Price</div>
            <div class="value">--</div>
          </div>
        </div>
        <div class="line-item">
          <div class="item">
            <div class="label">Packaging</div>
            <div class="value">{{ inquiry.packaging }}</div>
          </div>
          <div class="item">
            <div class="label">Shipment Date</div>
            <div class="value">{{ inquiry.shipment_date }}</div>
          </div>
          <div class="item">
            <div class="label">Price Idea</div>
            <div class="value">--</div>
          </div>
        </div>
        <div class="line-item">
          <div class="item">
            <div class="label">Required Documents</div>
            <div class="value">{{ inquiry.required_documents }}</div>
          </div>
          <div class="item">
            <div class="label">Note</div>
            <div class="value">{{ inquiry.note }}</div>
          </div>
        </div>
        <div class="line-item">
          <div class="item">
            <div class="label">Price</div>
            <div class="value">{{ inquiryQuote.price }}</div>
          </div>
        </div>
      </div>

      <div class="company">
        <div class="top">
          <div class="icon">
            <svg
              width="20"
              height="20"
              viewBox="0 0 24 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M21.1139 0.63764C21.546 0.224133 22.1208 -0.0044712 22.7173 6.62771e-05C23.3138 0.00460376 23.8852 0.241928 24.3109 0.661961C24.7366 1.08199 24.9834 1.65188 24.9992 2.25137C25.015 2.85086 24.7985 3.43307 24.3954 3.87513L12.1589 19.2609C11.9485 19.4888 11.6946 19.6716 11.4123 19.7985C11.1299 19.9255 10.8251 19.9938 10.5159 19.9996C10.2067 20.0054 9.89953 19.9484 9.61274 19.832C9.32595 19.7157 9.06545 19.5424 8.84679 19.3226L0.732068 11.1641C0.506086 10.9524 0.324832 10.6971 0.199119 10.4134C0.0734051 10.1298 0.00580698 9.82356 0.000357955 9.51306C-0.00509107 9.20256 0.0517208 8.89413 0.167403 8.60619C0.283086 8.31824 0.455269 8.05667 0.673682 7.83708C0.892095 7.61749 1.15226 7.44438 1.43866 7.32807C1.72507 7.21177 2.03183 7.15465 2.34067 7.16013C2.6495 7.16561 2.95408 7.23357 3.23622 7.35996C3.51836 7.48635 3.77229 7.66858 3.98286 7.89578L10.4047 14.3492L21.0557 0.705474C21.0748 0.681735 21.0953 0.659088 21.117 0.63764H21.1139Z"
                fill="#4ECB71"
              />
            </svg>
          </div>
          <div class="name">Company Information</div>
        </div>
        <div class="border"></div>
        <div class="info">
          <div class="item">
            <div class="label">Company</div>
            <div
              :class="['value', { 'show-value': activeValue == 'company' }]"
              @mouseover="showValue('company')"
            >
              {{ inquiryQuote.company.company_name }}
            </div>
          </div>
          <div class="item">
            <div class="label">Contact</div>
            <div
              :class="['value', { 'show-value': activeValue == 'contact' }]"
              @mouseover="showValue('contact')"
            >
              {{ inquiryQuote.user.first_name }}
            </div>
          </div>
          <div class="item">
            <div class="label">Email</div>
            <div
              :class="['value', { 'show-value': activeValue == 'email' }]"
              @mouseover="showValue('email')"
            >
              {{ inquiryQuote.user.email }}
            </div>
          </div>
        </div>
      </div>
      <div class="button">
        <div class="expired-cancel search-button" @click="cancel">Cancel</div>
      </div>
    </Modal>
  </div>
</template>

<script>
export default {
  props: ["inquiry", "inquiryQuote"],
  data() {
    return {
      inquiryForm: { price: "", note: "", id: "" },
      showSuccess: false,
      // inquriy: {}
      activeValue: ""
    };
  },
  mounted() {},
  methods: {
    showValue(val) {
      this.activeValue = val;
    },
    cancel() {
      this.showSuccess = false;
    }
  }
};
</script>
<style lang="less" scoped>
.pop-inquiry-success {
  /*background: #000;*/
  /*padding: 30px;*/
  .top {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 20px;
    padding-left: 15px;
    padding-right: 15px;
    .product {
      font-size: 20px;
      font-weight: 600;
      color: #222;
    }
    .status {
      width: 90px;
      height: 30px;
      border-radius: 5px;
      display: flex;
      justify-content: center !important;
      color: #fff;
      font-size: 13px;
      align-items: center;
    }
    .status-progress {
      background: #11828f;
    }
    .status-expired {
      background: #c0399f;
    }
    .status-completed {
      background: #925acf;
    }
  }
  .background-image {
    opacity: 0.08;
    position: absolute;
    bottom: 40px;
    left: 5%;
    max-width: 350px;
    img {
      width: 500px;
    }
  }
  .content {
    padding-left: 15px;
    padding-right: 15px;
    .line-item {
      display: flex;
      justify-content: space-between;
      margin-bottom: 15px;

      .item {
        width: 33%;
        .label {
          color: #8a8a8a;
          font-size: 14px;
        }
        .value {
          color: #444;
          font-size: 15px;
          font-weight: 600;
        }
      }
    }
  }
  .button {
    display: flex;
    justify-content: right;
    padding-left: 15px;
    padding-right: 15px;
    .cancel {
      color: #989696;
      font-size: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 10px;
    }
    .expired-cancel {
      background: #bababa;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 15px;
      color: #fff;
      padding: 5px 10px 5px 10px;
      border-radius: 5px;
    }
    .save {
      background: #f57f45;
      color: #fff;
      width: 75px;
      height: 35px;
      font-size: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
    }
  }
}
.input-value {
  margin-bottom: 15px;
  .label {
    color: #8a8a8a;
    font-size: 14px;
  }
  .input {
    input {
      border: 1px solid #c1c1c1;
      outline: none;
      width: 200px;
      height: 30px;
      border-radius: 5px;
      padding-left: 10px;
    }
  }
  .textarea {
    textarea {
      border: 1px solid #c1c1c1;
      outline: none;
      width: 200px;
      border-radius: 5px;
      padding-left: 10px;
    }
  }
}
.company {
  background: #f2f2f2;
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 30px;
  .top {
    display: flex;
    justify-content: center;
    border-bottom: 1px solid #cccccc;
    margin-top: 5px;
    padding-bottom: 10px;
    .icon {
      position: relative;
      top: 4px;
      right: 5px;
    }
    .name {
      font-size: 18px;
      font-weight: 600;
    }
  }
  .info {
    display: flex;
    justify-content: center;
    .item {
      width: 33%;
      .label {
        font-size: 14px;
        color: #8a8a8a;
        padding-left: 20px;
      }
      .value {
        font-size: 16px;
        color: #000;
        font-weight: 600;
        /*white-space: nowrap;*/
        /*overflow: hidden;*/
        /*text-overflow: ellipsis;*/
        padding-left: 20px;
        word-wrap: break-word;
      }
      .show-value {
        white-space: normal !important;
        word-wrap: break-word;
      }
    }
  }
}
</style>

<style lang="less">
.pop-inquiry {
  .ivu-modal {
    width: 620px !important;
    .ivu-modal-content {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}
</style>
