<template>
  <div class="main">
    <div class="box-top"></div>
    <div class="box">
      <div class="box-m"></div>

      <div class="sc-width main-box">
        <div class="cards">
          <Spin v-if="loading" fix></Spin>

          <div>
            <div class="back">
              <div
                class="back-icon search-button"
                @click="$router.push(`/my-quotations`)"
              >
                <svg
                  width="24"
                  height="23"
                  viewBox="0 0 24 23"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.04545 10H22.9545C23.2318 10 23.4977 10.158 23.6938 10.4393C23.8899 10.7206 24 11.1022 24 11.5C24 11.8978 23.8899 12.2794 23.6938 12.5607C23.4977 12.842 23.2318 13 22.9545 13H2.04545C1.76818 13 1.50227 12.842 1.30621 12.5607C1.11015 12.2794 1 11.8978 1 11.5C1 11.1022 1.11015 10.7206 1.30621 10.4393C1.50227 10.158 1.76818 10 2.04545 10Z"
                    fill="#666666"
                  />
                  <path
                    d="M2.41512 11.5L10.7068 21.0346C10.8945 21.2505 11 21.5433 11 21.8487C11 22.154 10.8945 22.4469 10.7068 22.6628C10.5191 22.8787 10.2645 23 9.99901 23C9.73354 23 9.47893 22.8787 9.29121 22.6628L0.293706 12.3141C0.200605 12.2073 0.12674 12.0804 0.0763414 11.9407C0.0259425 11.801 0 11.6512 0 11.5C0 11.3488 0.0259425 11.199 0.0763414 11.0593C0.12674 10.9196 0.200605 10.7927 0.293706 10.6859L9.29121 0.33721C9.47893 0.121298 9.73354 0 9.99901 0C10.2645 0 10.5191 0.121298 10.7068 0.33721C10.8945 0.553122 11 0.845962 11 1.15131C11 1.45665 10.8945 1.74949 10.7068 1.9654L2.41512 11.5Z"
                    fill="#666666"
                  />
                </svg>
              </div>
              <div class="back-name">My Quotaions</div>
            </div>
            <div class="product">
              <div class="product-name">
                <div class="name">
                  {{ inquiry.product_name }}
                </div>
                <div class="status-col">
                  <div
                    class="status status-progress"
                    v-if="inquiry.status == 1"
                  >
                    In progress
                  </div>
                  <div
                    class="status status-completed"
                    v-if="inquiry.status == 2"
                  >
                    Completed
                  </div>
                  <div class="status status-expired" v-if="inquiry.status == 3">
                    Expired
                  </div>
                </div>
              </div>
              <div class="product-detail">
                <div class="detail-item">
                  <div class="item-label">Specification</div>
                  <div class="item-value">{{ inquiry.quality_standard }}</div>
                </div>
                <div class="detail-item">
                  <div class="item-label">Quantity</div>
                  <div class="item-value">{{ inquiry.quantity }}</div>
                </div>
              </div>
            </div>
            <div class="list-table">
              <div class="title">
                <div class="item attendee font-medium">Company Name</div>
                <div class="item size font-medium">User</div>
                <div class="item date font-medium">Date</div>
                <div class="item date font-medium">Action</div>
              </div>
              <div class="content">
                <div
                  v-for="(item, index) in list"
                  :key="index"
                  :class="['item', { active: activeIndex === item.id }]"
                  @mouseover="companyMouseOver(item.id)"
                  @mouseleave="companyMouseLeave()"
                >
                  <div class="top search-button">
                    <div class="attendee font-medium">
                      {{ item.company.company_name }}
                    </div>
                    <div class="size font-medium">
                      {{ item.user.first_name }} {{ item.user.last_name }}
                    </div>

                    <div class="date font-medium">
                      {{ item.user.email }}
                    </div>
                    <div class="date font-medium">
                      {{ item.created_at | moment }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="empty" v-if="list.length === 0">
              No Data
            </div>
            <div class="page">
              <Page
                transfer
                :page-size="limit"
                :total="total"
                show-elevator
                show-total
                @on-change="pageChange"
                @on-page-size-change="pageSizeChange"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="box-m"></div>
    <Modal class="pop-choose" footer-hide v-model="showChoose">
      <div class="top">
        <div class="product">{{ inquiry.product_name }}</div>
        <div class="status status-progress">In progress</div>
      </div>
      <div class="content">
        <div class="line-item">
          <div class="item">
            <div class="label">Quantity</div>
            <div class="value">{{ inquiry.quantity }}</div>
          </div>
          <div class="item">
            <div class="label">Quality Standard</div>
            <div class="value">{{ inquiry.quality_standard }}</div>
          </div>
          <div class="item">
            <div class="label">Package Size</div>
            <div class="value">{{ inquiry.packaging }}</div>
          </div>
        </div>
      </div>
      <div class="input-value">
        <div class="label">Price(USD)<font color="red">*</font></div>
        <div class="value">{{ chooseItem.price }}</div>
      </div>
      <div class="footer">
        <div class="notice"></div>
        <div class="button">
          <div class="cancel search-button" @click="cancel()">Cancel</div>
          <div
            class="save search-button"
            @click="chooseQuote(chooseItem.id)"
            v-if="!buttonLoading"
          >
            Contact
          </div>
          <div class="save search-button loading-button" v-else>
            <img src="@/assets/loading.gif" />
          </div>
        </div>
      </div>
    </Modal>
    <PopInquirySuccess
      :inquiry="inquiry"
      :inquiryQuote="inquiryQuote"
      ref="popSuccess"
    ></PopInquirySuccess>
  </div>
</template>

<script>
import api from "@/api";
import { mapState } from "vuex";
import { uniq, trim } from "lodash";
import moment from "moment";
import PopInquirySuccess from "@/components/inquirySuccess";

const { _queryMySourcingQuotes, _chooseInquiryQuote } = api;

export default {
  name: "products",
  computed: {
    ...mapState("user", ["role"])
  },
  components: { PopInquirySuccess },
  data() {
    return {
      loginPop: false,
      confLoading: false,
      showInquire: false,
      inquireForm: {
        product_name: "-"
      },
      required: [
        {
          required: true,
          message: "Please fill"
        }
      ],
      curItem: {},
      modType: "card",
      page: 1,
      limit: 10, // must in 5-20
      total: 0,
      list: [],
      loading: false,
      cateList: [],
      countryList: [],
      filter: {
        product_name: ""
      },
      prices: ["CIF", "FOB", "Delivered", "Others"],
      price_type: "",
      justShowInquire: false,
      activeIndex: 0,
      showInstruction: false,
      allNum: 0,
      progressNum: 0,
      completedNum: 0,
      expiredNum: 0,
      checkValue: 0,
      checkValueArr: [
        { label: "All", checkValue: 0, value: 0 },
        { label: "In progress", checkValue: 1, value: 0 },
        { label: "Completed", checkValue: 2, value: 0 },
        { label: "Expired", checkValue: 3, value: 0 }
      ],
      inquiry: {},
      isShowMenu: 0,
      showChoose: false,
      chooseItem: {},
      inquiryForm: {},
      inquiryQuote: {},
      popSuccess: false,
      buttonLoading: true
    };
  },
  mounted() {
    this.getSourcings();
  },
  filters: {
    moment(val) {
      return moment
        .utc(val)
        .local()
        .fromNow();
    },
    changePrice(val) {
      return parseFloat(val);
    }
  },
  methods: {
    chooseQuote(id) {
      _chooseInquiryQuote({
        id: id
      }).finally(() => {
        this.showChoose = false;
        this.getSourcings();
      });
    },
    cancel() {
      this.showChoose = false;
    },
    viewQuote(item) {
      this.$refs.popSuccess.showSuccess = true;
      this.inquiryQuote = item;
    },
    choose(item) {
      this.showChoose = true;
      this.chooseItem = item;
    },
    showMenu(id) {
      this.isShowMenu == id ? (this.isShowMenu = 0) : (this.isShowMenu = id);
    },
    view(item) {
      this.$router.push(`/my-inquiries-res/${item.id}`);
    },
    changeCheckValue(val) {
      this.checkValue = val;
    },
    login() {
      if (this.role === "") {
        this.$refs.popLogin.showLogin = true;
        return;
      } else {
        this.$router.push(`/sourcing-inquiry`);
      }
    },
    jumpTo(path) {
      if (this.role === "") {
        this.$router.push(
          `/user-action/login?redirect=${this.$route.fullPath}`
        );
        return;
      }
      this.$router.push(path);
    },
    showInstructionFun() {
      this.showInstruction = !this.showInstruction;
    },
    companyMouseOver(e) {
      this.activeIndex = e;
    },
    companyMouseLeave() {
      this.activeIndex = null;
    },
    clearSearch() {
      this.page = 1;
      this.curBar = "";
      this.filter.product_name = "";
      this.getSourcings();
    },

    handleSearch() {
      this.page = 1;
      this.getSourcings();
    },

    pageChange(page) {
      this.page = page;
      this.getSourcings();
    },

    pageSizeChange(limit) {
      this.limit = limit;
      this.getSourcings();
    },
    getSourcings() {
      this.buttonLoading = true;
      this.loading = true;
      this.list = [];
      _queryMySourcingQuotes({
        page: this.page,
        limit: this.limit,
        ...this.filter,
        id: this.$route.params.id
      })
        .then(response => {
          const { data } = response;
          this.list = data[1].data;
          this.total = data[1].total;
          this.inquiry = data[0];
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          this.buttonLoading = false;
          this.loading = false;
        });
    },
    addCheckBox(key) {
      const value = trim(this[`${key}_temp`]);
      if (value) {
        const arr = uniq([...this[key], value]);
        this[key] = arr;
        this.industry_usage_grade_temp = "";
        this.compendial_standard_temp = "";
      }
    },
    handleReset(name) {
      this.$refs[name].resetFields();
    }
  }
};
</script>

<style lang="less" scoped>
.sc-width {
  /*background: #ffffff;*/
}
.box {
  margin: 126px auto 0 auto;
  background: #f2f2f2;
  .main-box {
    background: #fff;
    border-radius: 15px;
  }
}
.box-m {
  height: 24px;
  background: #f2f2f2;
}
.main-title-box {
  /*display: flex;*/
  align-items: center;
  justify-content: space-between;
  /*background: #fff;*/
  padding: 40px;
  /*border-bottom: 1px solid #ccc;*/
  margin: 0 auto;
  /*padding-top: 65px;*/
  padding-left: 0;
  padding-right: 0;
  .top {
    display: flex;
    justify-content: space-between;
  }
  .main-title {
    font-weight: 600;
    font-size: 30px;
    line-height: 37px;
    /* identical to box height */

    display: flex;
    align-items: center;

    color: #ffffff;
  }
  .exhibitor-sourcing {
    display: flex;
    justify-content: space-between;
  }
  .clearbtn2 {
    cursor: pointer;
    margin-left: 190px;
    padding: 2px 20px;
    color: #fff;
    line-height: 30px;
    background-color: @primary-color;
    border: 1px solid @primary-color;
    border-radius: 6px;
  }
  .extra {
    .total {
      color: #333;
      .des {
        font-size: 16px;
      }
      .num {
        font-weight: bold;
        font-size: 18px;
      }
    }
  }
  .my-sourcing {
    /*width: 200px;*/
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height */

    display: flex;
    align-items: center;
    text-align: center;

    /* Primary */

    color: #f57f45;
  }
  .add-sourcing {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 9px 20px;
    width: 241px;
    height: 38px;
    background: #f57f45;
    border-radius: 8px;

    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height */

    display: flex;
    align-items: center;
    text-align: center;

    /* White */

    color: #ffffff;

    /* Inside Auto Layout */

    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 10px;
  }

  .first {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height */

    display: flex;
    align-items: center;
    text-align: center;

    /* Gray 3 */

    color: #828282;
    margin-top: 22px;
    .user {
    }
    .view {
      color: #f57f45;
      margin-left: 5px;
    }
  }
  .instruction {
    display: flex;
    justify-content: space-around;
    margin-top: 30px;
    padding-left: 30px;
    padding-right: 30px;
    .step-item {
      .step-top {
        display: flex;
        .icon {
        }
        .word {
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          display: flex;
          align-items: center;

          /* Gray 2 */

          color: #ffffff;
          margin-left: 12px;
          position: relative;
          bottom: 2px;
        }
      }
      .pic {
        margin-top: 10px;
      }
      .first-pic {
        margin-top: 40px;
      }
    }
    .step-line {
    }
  }
  .hide-instruction {
    display: flex;
    justify-content: center;
    margin-top: 50px;
  }
}

.search-box {
  display: flex;
  align-items: center;
  /*border-radius: 50px;*/
  height: 35px;
  overflow: hidden;
  width: 45%;
  margin: 20px;
  .input-box {
    position: relative;
    flex: 1;
    height: 100%;
    background: #fff;
    line-height: initial;
    /*padding-left: 20px;*/
    /*padding-right: 20px;*/
    /*border: 1px solid #dfdfdf;*/
    /*border-right: none;*/
    /*border-top-left-radius: 40px;*/
    /*border-bottom-left-radius: 40px;*/
    border: 1px solid #ef7f21;
    border-radius: 5px;
    padding-left: 3px;
    padding-right: 3px;
    .search-icon {
      position: absolute;
      top: 7px;
      left: 10px;
      font-size: 20px;
      color: #888;
    }
    input {
      width: 100%;
      height: 100%;
      outline: none;
      background: transparent;
      border: none;
      color: #444;
      caret-color: #888;

      &::-webkit-input-placeholder {
        color: #bdcada;
      }
      &:-moz-placeholder {
        color: #bdcada;
      }
      &::-moz-placeholder {
        color: #bdcada;
      }
      &:-ms-input-placeholder {
        color: #bdcada;
      }
    }
  }
  .btn {
    cursor: pointer;
    background: #fff;
    color: @primary-color;
    border-radius: 5px;
    /*display: flex;*/
    align-items: center;
    justify-self: center;
    line-height: initial;
    height: 100%;
    margin-left: 20px;
    background: #ef7f21;
    font-size: 18px;

    font-weight: 500;
    color: #ffffff;
    line-height: 18px;
    text-align: center;
    /*padding-top: 9px;*/
    width: 120px;
    height: 35px;
  }
  .my-sourcing {
    width: 200px;
    height: 30px;
    background: linear-gradient(90deg, #f5a210, #ef8126);
    border-radius: 5px;
    font-size: 16px;

    font-weight: 400;
    color: #ffffff;
    line-height: 18px;
    text-align: center;
  }
  .clearbtn {
    margin-left: 10px;
    color: #666;
    border: 1px solid #ddd;
    border-radius: 20px;
  }
  .type-wrap {
    position: absolute;
    right: 20px;
    color: #444;
    font-size: 12px;
    text-align: right;
    .des {
      display: block;
    }
    .num {
      font-weight: bold;
      font-size: 14px;
    }
  }
}

// /deep/ .list-table .ivu-table-body .ivu-btn {
//   color: @primary-color;
//   border: 1px solid @primary-color;
// }

.sourcing {
  .ivu-form-item {
    margin-bottom: 15px;
  }
  .checkbox-box-input {
    width: initial;
    .ivu-input {
      border: 1px solid #ccc !important;
    }
  }
  .form-box-title {
    text-align: right;
    vertical-align: middle;
    float: left;
    font-size: 14px;
    color: #515a6e;
    line-height: 1;
    padding: 10px 12px 10px 0;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .compose {
    display: inline-block;
    width: 100%;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 6px;
  }
  .form-title-xing {
    justify-content: initial !important;
  }
  .form-title-xing:before {
    content: "*";
    display: inline-block;
    margin-right: 4px;
    line-height: 1;
    font-size: 14px;
    color: #ed4014;
  }
}

/deep/ .ivu-table-wrapper {
  overflow: inherit;
}

.search {
  background: #fff;
  display: flex;
  padding: 20px;
  border-bottom: 1px solid #ccc;
  .search-input {
    width: 400px;
  }
}
.main {
  background: #f2f2f2;
  .box-top {
    height: 27px;
  }
}
.cards {
  .table-title {
    font-weight: 500;
    font-size: 26px;
    line-height: 32px;
    /* identical to box height */

    display: flex;
    align-items: center;

    color: #000000;
    margin-bottom: 36px;
  }
}
.box {
  .empty {
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .box {
    margin-top: 206px;
    .empty {
      height: 300px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .cards {
      min-height: 300px;
      position: relative;

      .list-table {
        list-style: none;
        li {
          margin-bottom: 10px;
          background-color: #fff;
          border: 1px solid #f2f2f2;
          box-shadow: 0 0 4px #f8f8f8;
          .header {
            padding: 10px;
            border-bottom: 1px solid #f2f2f2;
            display: flex;
            justify-content: space-between;
            font-size: 12px;
            .wrap1 {
              min-width: 80%;
              cursor: pointer;
              text-align: left;
              .title {
                font-size: 14px;
              }
            }
            .wrap2 {
              text-align: right;
              .status {
                display: inline-block;
                background-color: #ddd;
                padding: 2px 8px;
                margin-bottom: 4px;
                &.pend {
                  background-color: #ddd;
                  color: #333;
                }
                &.acc {
                  background-color: #d5ffd2;
                  color: #418d3a;
                }
                &.rej {
                  background-color: #ffddd9;
                  color: #c04c3a;
                }
              }
              .time {
                color: #888;
              }
            }
          }
          .content {
            display: flex;
            padding-bottom: 10px;
            border-bottom: 1px solid #f2f2f2;
            .con {
              padding: 10px;
              .tit {
                color: #666;
                padding-bottom: 6px;
              }
              .tip {
                font-size: 12px;
              }
              .doc {
                color: #ff6600;
                a {
                  color: #ff6600;
                }
              }
            }
            .c1 {
              cursor: pointer;
            }
            .c1,
            .c2,
            .c3 {
              flex: 0 0 20%;
            }
            .c4 {
              flex: 0 0 40%;
            }
          }
        }
        .footer {
          padding: 10px;
          text-align: right;
          .numb {
            text-align: left;
            padding: 4px 10px;
            display: inline-block;
            background-color: #ffe7d6;
          }
        }
      }
      .page {
        margin: 10px 0;
        display: flex;
        justify-content: center;
        padding-bottom: 20px;
      }

      .ivu-poptip-body {
        h4 {
          font-size: 12px;
          text-align: left;
          color: #444;
          padding: 4px 0;
        }
        .tags {
          flex-wrap: wrap;
          display: flex;
          p {
            text-align: left;
            color: #999;
            flex: 0 0 50%;
            padding: 4px 0;
          }
        }
      }
    }
  }
  .list-table {
    .title {
      display: flex;
      height: 42px;
      background: #f2f2f2;
      border-radius: 5px;
      justify-content: space-between;
      font-size: 18px;

      font-weight: 500;
      color: #757575;
      line-height: 19px;
      .item {
        font-size: 16px;
        display: flex;
        justify-content: left;
        align-items: center;
        padding-left: 40px;
      }
      .product {
        width: 400px;
      }
      .attendee {
        width: 300px;
      }
      .size {
        width: 300px;
      }
      .status-col {
        width: 150px;
      }
      .date {
        width: 200px;
      }
      .document {
        width: 300px;
      }
    }
    .content {
      /*display: flex;*/
      .item {
        height: 60px;
        /*padding:0 30px;*/
        .top {
          display: flex;
          height: 60px;
          justify-content: space-between;
          border-bottom: 1px solid #ececec;
          div {
            display: flex;
            justify-content: left;
            align-items: center;
          }
          /*padding-top: 30px;*/
          /*div {*/
          /*  font-size: 16px;*/
          /*  */
          /*  font-weight: 500;*/
          /*  color: #323538;*/
          /*  line-height: 19px;*/
          /*  padding-left: 20px;*/
          /*}*/
          .size {
            padding-left: 40px;
          }
          .status-col {
            width: 150px;
            padding-left: 40px;
          }
          .product {
            width: 400px;
            .main-name {
            }
            .by-name {
              font-size: 12px;

              font-weight: 400;
              color: #999999;
              line-height: 19px;
            }
          }
          .attendee {
            width: 300px;
            padding-left: 40px;
          }
          .size {
            width: 300px;
            padding-left: 40px;
          }
          .date {
            width: 200px;
            padding-left: 40px;
            .choose {
              background: #f57f45;
              color: #fff;
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 15px;
              padding: 5px 8px;
              border-radius: 5px;
            }
            .choose-ban {
              background: #dddddd;
            }
            .choose-view {
              width: 72px;
            }
          }
          .document {
            width: 300px;
          }
        }
        .divided {
          /*width: 1440px;*/
          height: 1px;
          background: #cccccc;
        }
        .bottom {
          display: flex;
          justify-content: space-between;
          margin-top: 20px;
        }
      }
      .active {
        background: #f2f0f9;
      }
    }
  }
}
.page {
  margin: 10px 0;
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
}
.browse {
  cursor: pointer;
  color: #ef842d;
  padding-right: 20px;
}
.search-top-menu {
  display: flex;
  padding: 40px 0 10px 40px;
  .item {
    margin-right: 25px;
    .value {
      font-size: 18px;
    }
    .value-active {
      color: #f57f45;
      font-weight: 600;
    }
    .line {
      height: 2px;
      background-color: #f57f45;
      position: relative;
      top: 11px;
    }
  }
}
.border {
  border-top: 1px solid #ececec;
}
.status-col {
  margin-right: 80px;
  margin-top: 10px;
}
.status {
  width: 90px;
  height: 30px;
  border-radius: 5px;
  display: flex;
  justify-content: center !important;
  color: #fff;
  font-size: 13px;
  align-items: center;
}
.status-progress {
  background: #11828f;
}
.search {
  display: flex;
  padding-left: 7px;
  border: none;
  .search-icon {
    position: relative;
    left: 35px;
    top: 8px;
  }
  .search-input {
    input {
      background: #f2f2f2;
      outline: none;
      border-radius: 10px;
      border: none;
      width: 280px;
      height: 40px;
      padding-left: 45px;
    }
  }
}
.detail-menu {
  display: block !important;
  background: #fff;
  border-radius: 6px;
  width: 154px;
  height: 100px;
  position: relative;
  top: 40px;
  left: 15px;
  .menu-item {
    color: #25213b;
    font-size: 14px;
    padding: 10px 0 0 10px;
  }
  .menu-item:hover {
    background: #f2f0f9;
  }
  .menu-delete {
    color: #d30000;
  }
}
.back {
  display: flex;
  .back-icon {
    background: #ececec;
    width: 45px;
    height: 45px;
    border-radius: 40px;
    justify-content: center;
    display: flex;
    align-items: center;
    margin: 40px 15px 20px 40px;
  }
  .back-name {
    font-weight: 600;
    font-size: 20px;
    justify-content: center;
    display: flex;
    align-items: center;
    padding-top: 18px;
    color: #222;
  }
}
.product {
  .product-name {
    display: flex;
    justify-content: space-between;
    .name {
      font-size: 26px;
      font-weight: 600;
      padding: 0 0 20px 40px;
      color: #000;
    }
    .status {
      width: 90px;
      height: 30px;
      border-radius: 5px;
      display: flex;
      justify-content: center !important;
      color: #fff;
      font-size: 13px;
      align-items: center;
    }
    .status-progress {
      background: #11828f;
    }
    .status-expired {
      background: #c0399f;
    }
    .status-completed {
      background: #925acf;
    }
  }
  .product-detail {
    display: flex;
    padding-left: 40px;
    .detail-item {
      padding-right: 20px;
      padding-bottom: 20px;
      .item-label {
        font-size: 16px;
        color: #acacac;
        padding-bottom: 3px;
      }
      .item-value {
        font-size: 18px;
        color: #222;
      }
    }
  }
}
.pop-choose {
  padding: 30px;
  .top {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 20px;
    padding-left: 15px;
    padding-right: 15px;
    .product {
      font-size: 20px;
      font-weight: 600;
      color: #222;
    }
    .status {
      width: 90px;
      height: 30px;
      border-radius: 5px;
      display: flex;
      justify-content: center !important;
      color: #fff;
      font-size: 13px;
      align-items: center;
    }
    .status-progress {
      background: #11828f;
    }
  }
  .content {
    .line-item {
      display: flex;
      justify-content: space-between;
      margin-bottom: 15px;
      padding-left: 15px;
      padding-right: 15px;
      .item {
        width: 33%;
        .label {
          color: #8a8a8a;
          font-size: 14px;
        }
        .value {
          color: #444;
          font-size: 15px;
          font-weight: 600;
        }
      }
    }
  }
  .input-value {
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 15px;
    .label {
      font-size: 14px;
      color: #8a8a8a;
    }
    .value {
      color: #444;
      font-size: 15px;
      font-weight: 600;
    }
  }
  .button {
    display: flex;
    justify-content: right;
    padding-left: 15px;
    padding-right: 15px;
    .cancel {
      color: #989696;
      font-size: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 10px;
    }
    .save {
      background: #f57f45;
      color: #fff;
      width: 80px;
      height: 32px;
      font-size: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
    }
    .loading-button {
      opacity: 0.8;
      img {
        width: 18px;
      }
    }
  }
}
</style>
<style lang="less" scoped>
.ivu-table thead {
  background: linear-gradient(90deg, #a25d72, #2d3b81);
  opacity: 0.9;
}
.ivu-table th {
  background: none;
  font-size: 18px;

  font-weight: 500;
  color: #fefefe;
  line-height: 19px;
}
.list-table {
  width: 100%;
}
.main-box {
  margin: 0 auto;
}
.main-title-box-p {
  margin: 0 auto;
  background: #3b3951;
}
</style>
